import React,{ forwardRef } from "react";
import { EXPERIENCES } from "../constants";
import { motion } from "framer-motion";

const Experience = forwardRef((props,ref) => {
  return (
    <div ref={ref} className="border-b border-neutral-900 pb-4">
      <motion.h1
        whileInView={{ opacity: 1, y: 0 }}
        initial={{ opacity: 0, y: 50 }}
        transition={{ duration: 0.65 }}
        className="my-10 lg:my-20 text-center text-4xl"
      >
        Experience
      </motion.h1>
      {/* <motion.p
        whileInView={{ opacity: 1, x: 0 }}
        initial={{ opacity: 0, x: -100 }}
        transition={{ duration: 3, delay: 0 }}
        className="text-center text-sm tracking-tighter"
      > */}
      <div>
        {EXPERIENCES.map((experience, index) => {
          return (
            <div key={index} className="mb-8 flex flex-wrap lg:justify-center">
              <motion.div
                initial={{ x: -100, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.65, delay: 0.5 }}
                className="w-full lg:w-1/4"
              >
                <p className="text-sm text-neutral-400 mb-2">
                  {experience.year}
                </p>
              </motion.div>
              <motion.div
                initial={{ x: 100, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.65, delay: 0.5 }}
                className="w-full max-w-xl lg:w-3/4"
              >
                <h6 className="mb-2 font-semibold">
                  {experience.role} -{" "}
                  <span className="text-sm text-purple-100">
                    {experience.company}
                  </span>
                </h6>
                <p className="mb-4 text-neutral-400">
                  {experience.description}
                </p>
                <span className="flex flex-grow flex-wrap">
                  {experience.technologies.map((tech, index) => {
                    return (
                      <span
                        key={index}
                        className="mr-2 mt-4 rounded bg-neutral-900 px-2 py-1 text-sm font-medium text-purple-700 gap-2 "
                      >
                        {tech}
                      </span>
                    );
                  })}
                </span>
              </motion.div>
            </div>
          );
        })}
      </div>
      {/* </motion.p> */}
    </div>
  );
});

export default Experience;
